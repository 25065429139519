.accordion-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
  display: inline-block;
}

.accordion-button {
  float: right;
  margin-top: 5px;
  cursor: pointer;
  width: 22px;
}

.accordion-content-container {
  padding: 15px 3px 3px 0;
  width: 100%;
  display: none;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .accordion-title {
    font-size: 14px;
  }
}

.home-carousel-container {
  width: 100vw;
  margin: 0;
}

.home-carousel {
  background: #e5e5e5;
  z-index: 10;
}

.carousel-arrow {
  position: absolute;
  z-index: 50;
  height: 40px;
  top: 48%;
  cursor: pointer;
}

.carousel-arrow-left {
  left: 5%;
}

.carousel-arrow-right {
  right: 5%;
}

.carousel-dots {
  width: 20px;
  height: 20px;
}

.home-carousel-image-container {
  height: calc(100vh - 70px);
  min-height: 600px;
  max-height: 1080px;
  position: relative;
  overflow: hidden;
}

.home-carousel-image {
  min-width: 100%;
  min-height: 102%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-carousel-content {
  position: absolute;
  text-align: left;
  top: 27%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 52%;
}

.home-carousel-content-title {
  width: 90%;
  z-index: 50;
  color: #f1f2f3;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 40px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-carousel-content-text {
  display: inline-block;
  width: 580px;
  z-index: 50;
  color: #f1f2f3;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  font-size: 16px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-carousel-content-button {
  float: right;
  height: 45px;
  width: 160px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px !important;
  text-align: center;
  text-transform: uppercase;
}

.stats-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: calc(600px + 70px);
  max-height: calc(1080px + 70px);
}

.stats {
  position: absolute;
  width: 82%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 30px;
  bottom: 0;
  z-index: 10;
}

.stat-card-containter {
  padding: 0 15px;
}

.stat-card {
  display: inline-block;
  padding: 22px 0;
  background: black;
  opacity: 0.7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  height: 100px;
}

.stat-card-value {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 28px;
  text-align: center;
  color: #b2b2b2;
  opacity: 1;
}

.stat-card-text {
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 15px;
  text-align: center;

  color: #ffffff;
  opacity: 1;
}

.why-container {
  width: 100%;
  height: fit-content;
  margin-bottom: 5px;
}

.why-card {
  height: fit-content;
  margin: 0;
}

.why-banner {
  position: relative;
  height: 420px;
  background: #9e9e9e;
  background-blend-mode: multiply;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.why-modal-video {
  height: 540px;
  width: 900px;
}

.why-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 460px;
  width: 60%;
}

.why-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  max-width: 100px;
  min-width: 90px;
  width: 7%;
}

.why-play-button > img {
  cursor: pointer;
}

.why-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #00522c;

  margin-top: 45px;
  margin-left: 75px;
}

.why-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #00522c;

  margin-top: 30px;
  margin-left: 115px;
  margin-right: 100px;
}

.why-button {
  float: right;
  margin-top: 25px;
  margin-right: 15%;
  margin-bottom: 25px;
  width: 190px;
  height: 40px;
}

.howto-container {
  margin-top: 30px;
}

.howto-title {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 24px;
}

.howto-item {
  margin-top: 25px;
  margin-bottom: 25px;
}

.howto-index {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 45px;
  padding: 0;
  margin-top: -8px;
  display: inline-block;
  vertical-align: top;
  width: 40px;
}

.howto-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px);
}

.howto-button {
  height: 40px;
  width: 150px;
  float: right;
  margin-right: 45px;
}

.blog-container {
  width: 100%;
  margin-bottom: 3px;
}

.blog-card {
  height: fit-content;
  padding-bottom: 40px;
}

.blog-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #00522c;
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: 11px;
  margin-bottom: 30px;
}

.blog-carousel-content-container {
  max-width: 1296px;
  width: 100%;
  height: 420px;
  margin: 0 auto;
  position: relative;
}

.blog-article {
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.blog-article-image {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  height: 100%;
  object-fit: cover;
}

.blog-carousel-container {
  width: 100%;
  margin: 0 0 30px 0;
}

.blog-carousel-content-title {
  position: absolute;
  top: 42%;
  left: 40px;
  width: 85%;
  z-index: 50;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 26px;
  color: #ffffff;
  border-radius: 6px;
  height: 61px;
  overflow: hidden;
}

.blog-carousel-content-text {
  position: absolute;
  top: 61%;
  left: 0px;
  width: 100%;
  z-index: 50;
  padding: 0 40px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 15px;
  color: #ffffff;
  border-radius: 6px;
  height: 100px;
  overflow: hidden;
}

.blog-carousel-content-date {
  position: absolute;
  top: 90%;
  left: 20px;
  width: 85%;
  z-index: 50;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
}

.blog-button-container {
  max-width: 1296px;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  position: relative;
}

.blog-button {
  width: 190px;
  height: 40px;
  float: right;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 16px;
}

@media only screen and (max-width: 1800px) {
  .carousel-arrow-right {
    right: 3%;
  }

  .carousel-arrow-left {
    left: 3%;
  }

  .why-button {
    bottom: 5%;
  }
}

@media only screen and (max-width: 1600px) {
  .home-carousel-content {
    width: 70%;
    top: 19%;
  }

  .carousel-arrow-right {
    right: 2%;
  }

  .carousel-arrow-left {
    left: 2%;
  }

  .stat-card {
    padding: 10px 0;
    height: 80px;
  }

  .stat-card-value {
    font-size: 26px;
  }

  .why-text {
    margin-right: 80px;
  }

  .why-button {
    left: 40%;
  }

  .blog-carousel-content-container {
    max-width: 1034px;
    height: 380px;
  }

  .blog-carousel-content-title {
    font-size: 24px;
    height: 56px;
  }

  .blog-carousel-content-text {
    font-size: 14px;
  }

  .blog-carousel-content-date {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1300px) {
  .why-title {
    margin-top: 40px;
    margin-left: 50px;
  }

  .why-text {
    margin-left: 85px;
    margin-right: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-carousel-content {
    top: 22%;
  }

  .carousel-arrow-right {
    right: 3%;
  }

  .carousel-arrow-left {
    left: 3%;
  }

  .home-carousel-content-button {
    margin-top: 20px;
  }

  .stat-card-value {
    font-size: 26px;
  }

  .stat-card-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .home-carousel-content {
    top: 18%;
  }

  .home-carousel-content-title {
    font-size: 40px;
  }

  .home-carousel-content-text {
    width: 100%;
    font-size: 16px;
  }

  .home-carousel-content-button {
    margin-top: 30px;
  }

  .stat-card-containter {
    padding: 0 8px;
  }

  .why-play-button {
    top: 530px;
    width: 16%;
    min-width: 95px;
  }

  .why-banner {
    height: 420px;
  }

  .why-play-button {
    top: 420px;
  }

  .why-title {
    margin-top: 55px;
  }

  .howto-index {
    font-size: 55px;
  }

  .blog-card {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .howto-text-wide {
    width: 100%;
  }

  .home-carousel-content {
    top: 8%;
    width: 70%;
  }

  .home-carousel-content-title {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .home-carousel-content-text {
    line-height: 26px;
    font-size: 16px;
  }

  .home-carousel-content-button {
    margin-top: 30px;
  }

  .home-carousel-image-container {
    height: calc(80vh - 110px);
  }

  .home-carousel-content-button {
    height: 50px;
    width: 160px;
    font-size: 14px !important;
  }

  .stats-container {
    height: 77vh;
  }

  .stats {
    width: 94%;
  }

  .stat-card-value {
    font-size: 20px;
  }

  .stat-card-text {
    font-size: 12px;
  }

  .stat-card-containter {
    padding: 4px 4px;
  }

  .stat-card {
    height: 65px;
  }

  .why-banner {
    height: 380px;
  }

  .why-play-button {
    top: 380px;
  }

  .howto-index {
    margin-right: 20px;
  }

  .howto-button {
    width: 100%;
    float: none;
  }
}

@media only screen and (max-width: 650px) {
  .home-carousel-content-title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .stat-card-value {
    font-size: 16px;
  }

  .stat-card-text {
    font-size: 10px;
  }

  .stat-card {
    height: 60px;
  }

  .why-title {
    margin-right: 15px;
    margin-left: 15px;
  }

  .why-text {
    margin-right: 15px;
    margin-left: 15px;
  }

  .why-button {
    margin-right: 15px;
    margin-left: 15px;
    width: calc(100% - 30px);
    float: none;
  }
}

@media only screen and (max-width: 480px) {
  .home-carousel-content-button {
    height: 40px;
    width: 140px;
    font-size: 14px !important;
    padding: 0;
  }

  .home-carousel-content-text {
    line-height: 16px;
    font-size: 16px;
  }

  .why-banner {
    height: 300px;
  }

  .why-play-button {
    top: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .home-carousel-image-container {
    height: calc(74vh - 110px);
  }

  .stats-container {
    height: 74vh;
  }

  .home-carousel-content {
    width: 70%;
  }

  .home-carousel-content-title {
    font-size: 26px;
  }

  .home-carousel-content-text {
    font-size: 12px;
  }

  .home-carousel-content-button {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .stat-card-value {
    font-size: 14px;
  }

  .stat-card-text {
    font-size: 8px;
  }

  .stat-card {
    height: 54px;
  }

  .why-banner {
    height: 280px;
  }

  .why-play-button {
    top: 280px;
  }
}

.master-users-list-item {
  font-style: normal;
  font-weight: normal;
  color: #00522c;
}

@media only screen and (max-width: 768px) {
  .master-users-list-item-name {
    font-size: 16px;
  }

  .master-users-list-item-email {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .master-users-list-item-name {
    font-size: 14px;
  }

  .master-users-list-item-email {
    font-size: 12px;
  }
}

.sortByComponent {
  position: relative;
  right: 60px;
  padding-right: 60px;
  float: right;
}

.sortBySelect {
  width: 100%;
  height: 50px;
}

.searchOptionsContainer {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.searchOptionsSelect {
  height: 40px;
  width: 100%;
}

.fixRowMargin {
  margin-left: 0px;
}

.dateRangeDiv {
  padding-left: 0px;
  margin-top: 33px;
}

.dateRangeInput {
  margin: 0px;
  margin-top: 6px;
  padding: 0px;
  height: 40px;
  width: 100%;
}

.noPaddingRight {
  padding-right: 0px;
}

.noPaddingLeft {
  padding-left: 0px;
}

.supporter-user-list-item-container {
  font-style: normal;
  font-size: 14px;
  color: #00522c;
}

@media only screen and (max-width: 768px) {
  .supporter-user-list-item-container {
    font-size: 14px;
  }
}

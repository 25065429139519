.terms-of-use-title {
  font-weight: bold;
  font-style: medium;
  font-size: 36px;
  line-height: 41px;
  text-align: left;
  color: #00522c;
  margin-bottom: 55px;
  margin-top: 50px;
}

.terms-of-use-text {
  font-family: Ubuntu;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: #00522c;
  margin-bottom: 20px;
}

.terms-of-use-container {
  padding-top: 75px;
  padding-bottom: 75px;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 10px;
}

.loader-container {
  width: 100%;
  padding: 350px;
  margin-bottom: 400px;
}

.loader {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how-to-support-container {
  padding-bottom: 15px;
  width: 100%;
  margin-bottom: 343px;
}

.howto-intern-item {
  margin-top: 15px;
  margin-bottom: 30px;
}

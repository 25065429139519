.waituploadmodal-container {
  padding: 40px;
}

.waituploadmodal-loader {
  text-align: center;
}

.waituploadmodal-message {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 24px;
  color: #00522c;
  text-align: center;
  margin: 30px 30px 0 30px;
}

@media only screen and (max-width: 768px) {
  .waituploadmodal-container {
    padding: 20px;
  }

  .waituploadmodal-message {
    margin: 10px 10px 0 10px;
  }
}

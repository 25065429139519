.badgestack-container {
  position: relative;
  height: 60px;
  width: 60px;
  margin: 20px 10px;
}

.badgestack-item {
  position: absolute;
  width: 60px;
  z-index: 5;
}

.badgestack-plus-icon {
  position: absolute;
  z-index: 5;
}

.badgestack-tooltip-container {
  position: fixed;
  background: rgba(253, 253, 253, 0.9);
  border-radius: 6px;
  z-index: 100;
  text-align: start;
}

.badgestack-tooltip-item {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #000000;
  margin: 3px 5px;
}

.banner-list-item-image-container {
  position: relative;
  display: inline-block;
  height: 140px;
  width: 236px;
  border-radius: 3px;
  overflow: hidden;
}

.banner-list-item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-list-body-text {
  font-style: normal;
  font-size: 14px;
  color: #00522c;
  max-width: 673px;
  margin-left: 20px;
  margin-right: 0;
  display: inline-block;
}

.banner-list-item-image-choose {
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  width: 168px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 0;
  cursor: pointer;
}

.banner-list-image-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-list-image-placeholder-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stat-list-item-container {
  font-style: normal;
  font-size: 14px;
  color: #00522c;
  width: 800px;
}

.banner-form-input-text {
  float: left;
  width: calc(100% - 295px);
  margin: 0 15px;
}

.banner-form-input-image {
  float: right;
  margin: 0 15px;
  width: 235px;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 992px) {
  .banner-list-body-text {
    max-width: 100%;
  }
}

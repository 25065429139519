.button-file {
  background-image: url("https://vbio.blob.core.windows.net/public/assets/img/attach-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 26px;
  width: 30px;
  border: none;
  cursor: pointer;
}

.button-file > input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}

.supporter-user-list-item-container {
  font-style: normal;
  font-size: 14px;
  color: #00522c;
}

@media only screen and (max-width: 768px) {
  .supporter-user-list-item-container {
    font-size: 14px;
  }
}

.about-item {
  margin-top: 15px;
}

.about-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  display: inline-block;
  vertical-align: top;
}

.about-icon {
  display: inline-block;
  margin-right: 15px;
}

.about-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #00522c;
}

.differential-container {
  margin-top: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.differential-box-items {
  background: #f7f8f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 15px;
}

.about-container {
  width: 100%;
}

.about-objective-image {
  width: 100%;
  margin-bottom: 30px;
}

.about-accordion-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;

  padding-left: 80px;
  padding-right: 100px;
}

.about-aoi-icon {
  margin-top: 10px;
  width: 55px;
  height: 55px;
}

.about-ods-icon {
  margin-top: 10px;
}

.about-aoi-icon img {
  width: 100%;
  height: auto;
}

.about-subtitle {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .about-accordion-body {
    padding-left: 30px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .about-subtitle {
    font-size: 13px;
  }

  .about-accordion-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.progress-bar-container1 {
  position: relative;
}

.progress-bar-container2 {
  background: #f7f8f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  width: 100%;
  height: 35px;
  overflow: hidden;
}

.progress-bar {
  background: #24a862;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 0;
}

.progress-bar-marker {
  position: absolute;
  top: -8px;
  background-color: #353535;
  width: 2px;
  height: 51px;
  z-index: 5;
}

.progress-bar-marker-label {
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 16px;
  color: #353535;
  white-space: nowrap;
  position: absolute;
  top: -30px;
  left: -1px;
}

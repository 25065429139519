.new-project-title {
  margin-top: 30px;
  margin-bottom: 15px;
}

.new-project-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #00522c;
  margin-top: 40px;
  margin-bottom: 30px;
  vertical-align: middle;
}

.new-project-text-link {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #00522c;
  margin-top: 40px;
  margin-bottom: 30px;
  vertical-align: middle;
}

.new-project-manual-container {
  margin-bottom: 30px;
}

.new-project-manual-container > a {
  text-decoration-color: #00522c;
}

.new-project-section-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #00522c;
  margin-bottom: 10px;
}

.new-project-section-container {
  margin-bottom: 10px;
}

.new-project-card-checkbox-container {
  margin-bottom: 30px;
}

.new-project-button-bar {
  margin-top: 15px;
  margin-bottom: 30px;
}

.new-project-base-button {
  height: 40px;
  width: 100%;
}

.new-project-save-button {
  background: #f2f3f3;
  color: #24a862 !important;
}

.new-project-save-button:hover {
  background: #f2f3f3;
}

.new-project-add-indicator-button {
  width: 100%;
  height: 40px;
  background: #f2f3f3;
  color: #00522c !important;
}

.new-project-add-indicator-button:hover {
  background: #f2f3f3;
}

.new-project-add-indicator-icon {
  float: left;
  margin-top: -3px;
  margin-left: 15px;
  margin-right: 15px;
}

.new-project-add-gratification-button {
  width: 100%;
  height: 40px;
  background: #f2f3f3;
  color: #00522c !important;
}

.new-project-add-gratification-button:hover {
  background: #f2f3f3;
}

.new-project-add-gratification-icon {
  float: left;
  margin-top: -3px;
  margin-left: 15px;
  margin-right: 15px;
}

.new-project-indicator-field-container {
  display: inline-block;
  width: calc(100% - 46px);
}

.new-project-remove-indicator-button {
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 15px;
  cursor: pointer;
  height: 25px;
}

.new-project-remove-gratification-button {
  float: right;
  cursor: pointer;
  height: 25px;
}

.new-project-image-add-button {
  margin: 65px 45px;
  cursor: pointer;
  height: 40px;
}

.new-project-share-label {
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 22px;
  color: #00522c;
  margin: 5px 0;
}

.new-project-submission-icon {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}

.new-project-submission-label {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #00522c;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.new-project-submission-list {
  margin-top: 20px;
}

.new-project-submission-list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
}

.new-submission-terms-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
  vertical-align: middle;
}

@media only screen and (max-width: 992px) {
  .new-project-text {
    line-height: 24px;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .new-project-base-button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 408px) {
  .new-project-add-indicator-button {
    padding: 12px 0;
  }

  .new-project-add-indicator-icon {
    margin-top: 3px;
  }
}

@media only screen and (max-width: 357px) {
  .new-project-add-gratification-button {
    padding: 12px 0;
  }

  .new-project-add-gratification-icon {
    margin-top: 3px;
  }
}

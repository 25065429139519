.my-projects-page-title-label {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 26px;
  color: #00522c;
}

.my-projects-button {
  float: right;
  height: 40px;
  width: 100%;
}

.my-projects-alert-container {
  font-style: normal;
  color: #00522c;
  margin-top: 20px;
}

.my-projects-alert-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 7px;
}

.my-projects-alert-card-container {
  position: relative;
}

.my-projects-alert {
  font-size: 12px;
  min-height: 60px;
  padding: 10px 15px;
}

.my-projects-list-container {
  position: relative;
  margin-bottom: 40px;
}

.my-projects-project-image-container {
  position: relative;
  height: 190px;
  width: 30%;
}

.my-projects-project-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-projects-project-icon {
  position: absolute;
  top: 15px;
  left: 15px;
}

.my-projects-project-badges-container {
  position: absolute;
  left: calc(30% - 60px);
  bottom: 10px;
  width: 110px;
  text-align: center;
}

.my-projects-project-content-container {
  position: absolute;
  left: 36%;
  top: 0;
  width: 58%;
  height: 100%;
  margin-left: 20px;
  padding-top: 17px;
}

.my-projects-project-shortname-container {
  overflow: hidden;
  height: 56px;
  float: left;
}

.my-projects-project-shortname {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  color: #00522c;
  display: inline-block;
  position: relative;
}

.my-projects-project-shortname::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 135px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(242, 243, 243, 0),
    rgba(242, 243, 243, 0),
    rgba(242, 243, 243, 1) 90%
  );
}

.my-projects-project-toolbar {
  float: right;
}

.my-projects-project-toolbar-item {
  margin-left: 15px;
  cursor: pointer;
  z-index: 10;
  height: 20px;
}

.my-projects-project-bar-container {
  margin-top: 40px;
  margin-bottom: 24px;
}

.my-projects-project-status {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00522c;
}

.my-projects-intern-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #00522c;
  display: inline-block;
  margin-bottom: 15px;
}

.my-projects-intern-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
}

.my-projects-intern-edit-button {
  height: 40px;
  float: right;
  cursor: pointer;
}

.my-projects-intern-carousel-container {
  position: relative;
  margin-top: 20px;
}

.my-projects-intern-badges-container {
  position: absolute;
  bottom: -30px;
  left: 5%;
  z-index: 10;
}

.my-projects-intern-badge {
  margin: 0 10px;
  z-index: 5;
  width: 70px;
}

.my-projects-intern-carousel {
  background: #e5e5e5;
  z-index: 5;
}

.my-projects-intern-carousel-image-container {
  height: 700px;
  position: relative;
  overflow: hidden;
}

.my-projects-intern-carousel-image {
  min-width: 100%;
  min-height: 102%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my-projects-intern-goals {
  background-color: #f2f3f3;
  margin-top: 100px;
  padding-bottom: 30px;
}

.my-projects-intern-progress-bar-container {
  margin-top: 80px;
}

.my-projects-intern-values-container {
  margin-top: 74px;
}

.my-projects-intern-operation {
  margin-top: 30px;
  font-size: 20px;
  color: #00522c;
}

.my-projects-intern-operation {
  padding: 12px 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #f7f8f9;
}

.my-projects-intern-operation-download {
  margin-left: 15px;
  height: 24px;
}

.my-projects-intern-values {
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
  text-align: center;
}

.my-projects-intern-values {
  background: #00522c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 20px 30px;
}

.my-projects-intern-values-title {
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 22px;
  color: #ffffff;
}

.my-projects-intern-values-value {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 30px;
  color: #ffffff;

  margin-top: 20px;
}

.my-projects-intern-support-list-container {
  margin-bottom: 40px;
}

.my-projects-intern-support-list-title {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 24px;
  color: #00522c;
  margin-bottom: 10px;
}

.my-projects-intern-support-list-header {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  margin: 10px 30px 0 30px;
}

.my-projects-intern-support-list-item {
  background: #f7f8f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 100%;
  height: 60px;
  display: inline-table;
}

.my-projects-intern-support-list-item-body {
  display: table-cell;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  line-height: 60px;
  font-size: 14px;
  color: #00522c;
}

.my-projects-intern-bottom-bar {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #24a862;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .my-projects-intern-support-list-header {
    font-size: 14px;
  }

  .my-projects-intern-support-list-item-body {
    font-size: 12px;
    line-height: 18px;
    padding: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .my-projects-button {
    margin-top: 15px;
  }

  .my-projects-intern-operation {
    padding: 25px 30px 20px 30px;
  }

  .my-projects-intern-operation-download {
    margin-left: 12px;
    height: 20px;
  }
}

.checkbox-container {
  background: #f7f8f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox {
  margin: 0;
  opacity: 0;
  width: 50px;
  height: 50px;
}

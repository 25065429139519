.dashboard-stat-card {
  margin-bottom: 15px;
  background: #00522c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 92px;

  text-align: center;
  padding: 15px 0;
}

.dashboard-stat-card-value {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #b2d58f;
  margin-bottom: 6px;
}

.dashboard-stat-card-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.dashboard-chart-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #00522c;
  margin-bottom: 20px;
}

.dashboard-chart-container {
  margin-top: 20px;
}

.dashboard-chart-legend {
  text-align: center;
}

.dashboard-ranking-vert-label {
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 16px;
  color: #00522c;
  display: inline-block;
  transform: rotate(-90deg);
  vertical-align: middle;
  text-align: center;
  height: 45px;
  width: 100px;
  margin-left: -40px;
  margin-right: -70px;
}

.dashboard-ranking-chart-container {
  display: inline-block;
  vertical-align: middle;
  width: calc(75% - 10px);
}

.dashboard-chart-legend-inline {
  display: inline-block;
  vertical-align: middle;
  width: 25%;
  margin-bottom: 30px;
}

.dashboard-chart-legend-inline > div > div {
  padding-right: 0;
}

.rv-xy-plot {
  margin: auto;
}

@media only screen and (max-width: 992px) {
  .dashboard-chart-legend-inline > div > div > span {
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-chart-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .dashboard-chart-legend > div > div > span {
    font-size: 9px;
  }

  .dashboard-ranking-chart-container {
    width: calc(70% - 10px);
  }

  .dashboard-chart-legend-inline {
    width: 30%;
  }
}

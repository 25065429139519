.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.vitrine {
  background: #fbfbfb;
  margin-top: 30px;
  margin-bottom: 10px;
}

.vitrine > a {
  text-decoration: none;
}

.vitrine-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
  color: #00522c;
  margin-bottom: 8px;
  width: fit-content;
}

.vitrine-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  width: 100%;
  margin-bottom: 20px;
}

.vitrine-main-project-container {
  position: relative;
  background-color: #f2f3f3;
  height: 600px;
  margin-bottom: 100px;
}

.vitrine-main-project-image-container {
  position: relative;
  height: 320px;
  width: 50%;
}

.vitrine-main-project-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vitrine-main-project-name-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%);
  width: 50%;
  margin-left: 75px;
}

.vitrine-main-project-shortname-container {
  overflow: hidden;
  height: 58px;
  margin-right: 100px;
}

.vitrine-main-project-description-container {
  overflow: hidden;
  height: 150px;
  margin-right: 100px;
}

.vitrine-main-project-description {
  color: #00522c;
  font-size: 15px;
}

.vitrine-main-project-shortname {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  color: #00522c;
  margin-right: 40px;

  position: relative;
}

.vitrine-main-project-shortname::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 165px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(247, 248, 249, 0),
    rgba(247, 248, 249, 0),
    rgba(247, 248, 248, 1) 90%
  );
}

.vitrine-main-project-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #00522c;
  margin: 46px 130px 0 0;
}

.vitrine-main-project-icon {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 80px;
}

.vitrine-main-project-badges-container {
  position: absolute;
  left: calc(50% - 60px);
  bottom: 25px;
  width: 120px;
  text-align: center;
}

.vitrine-main-project-details-container {
  margin: 20px 0 10px 0;
  width: 100%;
}

.vitrine-main-project-location {
  width: 100%;
  overflow: hidden;
  height: 45px;
}

.vitrine-main-project-details-biomes-container {
  overflow: hidden;
  height: 40px;
}

.vitrine-main-project-biome {
  display: inline-block;
  margin-right: 22px;
  overflow: hidden;
  white-space: nowrap;
}

.vitrine-main-project-details-icon {
  float: left;
  margin-right: 16px;
  margin-top: 5px;
}

.vitrine-main-project-details-text {
  margin: 8px 0 0 0;
}

.vitrine-main-project-details-small-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #00522c;
  display: inline-block;
}

.vitrine-project-badge {
  margin: 4px;
  z-index: 5;
  width: 60px;
}

.vitrine-projects-container {
  width: auto;
  padding: 0;
}

.vitrine-projects-tabs {
  padding: 0 15px;
}

.vitrine-projects-tabs-item {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #24a862;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  width: 60px;
  cursor: pointer;
}

.vitrine-projects-tabs-item-selected {
  color: #00522c;
}

.vitrine-projects-tabs-item-selected::after {
  content: " ";
  display: block;
  background: #00522c;
  height: 2px;
  width: 60px;
  margin: 5px auto;
}

.vitrine-card-container {
  padding: 15px;
}

.vitrine-project-container {
  position: relative;
  background-color: #f2f3f3;
}

.vitrine-project-image-container {
  position: relative;
  height: 225px;
  width: 100%;
}

.vitrine-project-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vitrine-project-shortname-container {
  overflow: hidden;
  height: 50px;
  margin: 42px 29px 8px 29px;
}

.vitrine-project-shortname {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #00522c;
  position: relative;
}

.vitrine-project-shortname::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 124px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(247, 248, 249, 0),
    rgba(247, 248, 249, 0),
    rgba(247, 248, 248, 1) 90%
  );
}

.vitrine-project-name-container {
  overflow: hidden;
  height: 42px;
  margin: 0px 29px 15px 29px;
}

.vitrine-project-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  position: relative;
}

.vitrine-project-icon {
  position: absolute;
  top: 18px;
  left: 18px;
  width: 65px;
}

.vitrine-project-badges-container {
  position: absolute;
  right: 8px;
  top: calc(225px - 28px);
  width: fit-content;
  height: 60px;
  vertical-align: middle;
}

.vitrine-projects-button {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #24a862;
  float: right;
  margin-bottom: 32px;
  cursor: pointer;
}

@media only screen and (max-width: 1600px) {
  .vitrine-main-project-badges-container {
    bottom: 15px;
  }

  .vitrine-project-image-container {
    height: 215px;
  }

  .vitrine-project-icon {
    top: 12px;
    left: 12px;
    width: 60px;
  }

  .vitrine-project-badges-container {
    top: calc(215px - 28px);
  }
}

@media only screen and (max-width: 992px) {
  .row.display-flex {
    display: block;
  }

  .vitrine-project-image-container {
    height: 400px;
  }

  .vitrine-project-badges-container {
    top: calc(405px - 32px);
  }
}

@media only screen and (max-width: 768px) {
  .vitrine-container {
    margin-bottom: 250px;
  }

  .vitrine-project-image-container {
    height: 400px;
  }

  .vitrine-project-badges-container {
    top: calc(400px - 32px);
  }
}

@media only screen and (max-width: 620px) {
  .vitrine-project-image-container {
    height: 300px;
  }

  .vitrine-project-badges-container {
    top: calc(300px - 32px);
  }

  .vitrine-projects-tabs-item {
    font-size: 15px;
    margin-right: 28px;
  }

  .vitrine-projects-tabs-item-selected::after {
    margin: 6px auto;
  }
}

@media only screen and (max-width: 500px) {
  .vitrine-project-image-container {
    height: 250px;
  }

  .vitrine-project-badges-container {
    top: calc(250px - 32px);
  }

  .vitrine-projects-tabs-item {
    font-size: 15px;
    margin-right: 16px;
  }
}

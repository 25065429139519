.menu {
  position: relative;
  float: right;
  top: 17px;
  width: auto;
  padding: 0;
}

.menu-item {
  position: relative;
  display: inline-block;
  color: #24a862;
  margin-left: 4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  min-width: 50px;
  margin-right: 6px;
  font-size: 14px;
}

.menu-item-selected,
.menu-item:hover,
.menu-item:focus,
.menu-item:active {
  color: #00522c;
}

.menu-item-selected-after {
  display: block;
  background: #00522c;
  height: 2px;
  width: 50px;
  margin: 6px auto;
}

.menu-item-bold {
  font-weight: bold;
}

.menu-item-button {
  padding: 8px 26px;
  font-size: 12px !important;
}

.menu-item-button-yellow {
  background-color: #efa500;
}

.menu-item-button-yellow:hover,
.menu-item-button-yellow:active {
  background-color: #ffba1f;
}

.menu-item-button-blue {
  background-color: #2e91d5;
}

.menu-item-button-blue:hover,
.menu-item-button-blue:active {
  background-color: #51a9e6;
}

.menu-item-dropdown-icon {
  margin-left: 8px;
  width: 15px;
}

.menu-item-notification {
  display: inline-block;
  height: 6px;
  width: 6px;
  background-color: #b73520;
  border-radius: 3px;
  margin-left: 8px;
  margin-bottom: 2px;
}

.menu-language-item {
  display: inline-block;
  max-width: 28px;
  margin-top: -2px;
  margin-left: 16px;
  vertical-align: top;
}

.menu-language-subitem {
  margin-bottom: 7px;
  width: 28px;
}

.menu-submenu-detail {
  position: absolute;
  top: -10px;
  background: #24a862;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
}

.menu-submenu-content {
  position: absolute;
  top: 10px;
  background: #24a862;
  width: fit-content;
  z-index: 100;
}

.menu-submenu-content > a {
  text-decoration: none;
}

.menu-submenu-item {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 10px 30px;
  white-space: nowrap;
  text-decoration: none;
}

.menu-submenu-item::after {
  display: block;
  content: attr(label);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}

.menu-submenu-item:hover,
.menu-submenu-item:focus,
.menu-submenu-item:active {
  font-weight: bold;
}

.menu-burger-container {
  padding: 30px;
  background-color: #f2f3f3;
  min-height: 100%;
}

.menu-burger-close-button {
  float: right;
  cursor: pointer;
  transform: rotate(45deg);
  width: 25px;
}

.menu-burger-item {
  margin: 8px 0px;
  font-size: 22px;
  width: 100%;
}

.menu-burger-sub-item {
  color: #24a862;
  margin-left: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  cursor: pointer;

  margin: 10px 0 10px 60px;
}

@media only screen and (max-width: 1800px) {
}

@media only screen and (max-width: 1400px) {
  .menu-item {
    margin-right: 4px;
  }

  .menu-language-item {
    margin-left: 12px;
  }

  .menu-item-notification {
    margin-left: 6px;
  }
}

@media only screen and (max-width: 1300px) {
  .menu-item {
    margin-right: 4px;
    font-size: 15px;
  }

  .menu-item-notification {
    margin-left: 4px;
  }

  .menu-language-item {
    margin-left: 6px;
  }
}

@media only screen and (max-width: 1300px) {
  .menu-item {
    text-align: left;
  }
}

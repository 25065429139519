.project-intern-back-button-container {
  display: inline-block;
  width: 120px;
}

.project-intern-toolbar-container {
  float: right;
}

.project-intern-toolbar-item {
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 15px;
}

.project-intern-toolbar-item > img {
  height: 40px;
}

.project-intern-toolbar-item > a > img {
  height: 40px;
}

.project-intern-toolbar-button {
  width: 190px;
  height: 40px;
}

.projects-back-button-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.project-intern-toolbar-alert-label {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  color: #00522c;
  text-align: center;
  margin-top: 15px;
  min-height: 10px;
}

.project-intern-toolbar-text {
  font-style: normal;
  font-weight: normal;
  text-align: right;
  color: #00522c;
}

.project-inter-toolbar-edit-date {
  float: right;
}

.projects-search-container {
  padding: 0 30px;
}

.projects-search-field {
  margin-bottom: 15px;
}

.projects-accordion-container {
  margin: 20px 0;
}

.projects-search-container .form-group {
  margin-bottom: 10px;
}

.projects-search-section-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
  margin-top: 15px;
  margin-bottom: 7px;
}

.projects-search-text-container {
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #00522c;
  margin-top: 30px;
  margin-bottom: 30px;
}

#project-intern-header {
  background-color: #f2f3f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  height: 50px;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
}

#project-intern-header-fixed-value {
  font-weight: bold;
  font-size: 18px;
  color: #24a862;
  margin-top: 12px;
}

#project-intern-header-fixed-support {
  background-color: #2e91d5;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px !important;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 200px;
  height: 50px;
  margin-right: 100px;
}
#project-intern-header-fixed-support:hover,
#project-intern-header-fixed-support:active {
  background-color: #51a9e6;
}

#project-intern-titles h2 {
  font-weight: 500;
  font-size: 26px;
  font-style: normal;
  color: #00522c;
  margin-top: 0;
  margin-bottom: 15px;
}

#project-intern-titles h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
  margin: 0;
}

.project-intern-carousel-container {
  position: relative;
  margin-top: 20px;
}

.project-intern-carousel-toolbar-container {
  position: absolute;
  top: -38px;
  left: 60px;
  width: 87%;
  z-index: 10;
}

.project-intern-badges-container {
  float: left;
  z-index: 10;
}

.project-intern-badge {
  margin: 0 10px;
  z-index: 5;
  width: 70px;
}

.project-intern-button-container {
  margin-top: 9px;
  float: right;
  z-index: 10;
}

.project-intern-button {
  background-color: #2e91d5;
  width: 200px;
  height: 50px;
  font-size: 16px !important;
}
.project-intern-button:hover,
.project-intern-button:hover:active {
  background-color: #51a9e6;
}

.project-intern-carousel {
  z-index: 5;
}

.project-intern-carousel-image-container {
  height: 560px;
  position: relative;
  overflow: hidden;
}

.project-intern-carousel-image {
  min-width: 100%;
  min-height: 102%;
  height: 560px;
  object-fit: cover;
  object-position: center;
}

.project-intern-summary-title {
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 32px;
  color: #00522c;
  margin-top: 60px;
  margin-bottom: 20px;
}

.project-intern-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
  margin-top: 30px;
}

#project-intern-support {
  background-color: #eceded;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #00522c;
  font-size: 14px;
}

#project-intern-support .margin-space {
  padding-top: 12px;
}

#project-intern-goals {
  background-color: #f2f3f3;
  margin-top: 100px;
  padding-bottom: 12px;
}

.goals-icon {
  height: 45px;
  margin-right: 20px;
}

#project-intern-operation {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 28px;
  color: #00522c;
}

#project-intern-operation .container {
  padding-left: 80px;
  padding-right: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #f7f8f9;
  padding-top: 40px;
  padding-bottom: 40px;
}

#project-intern-operation a {
  color: #00522c;
}

#project-intern-operation-download {
  margin-left: 47px;
  height: 60px;
}

.project-intern-values {
  margin: 30px 0 0 0;
  padding: 20px 30px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.project-intern-values-green {
  background: #00522c;
  color: white !important;
}

.project-intern-values-gray {
  background: #f2f3f3;
  color: #00522c !important;
}

.project-intern-values-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.project-intern-values-value {
  font-style: normal;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 4px;
}

.gratification-card {
  background: #f7f8f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 15px;
}

.gratification-card .gratification-title {
  color: #24a862;
  font-size: 20px;
}

.gratification-card .gratification-desc {
  font-size: 14px;
  color: #00522c;
}

#project-intern-supporters {
  margin-top: 50px;
}

#project-intern-supporters .table-striped > tbody > tr > td {
  padding: 30px;
  border-top: none;
  color: #00522c;
}

#project-intern-supporters .table-striped > tbody > tr:nth-of-type(odd) {
  background: #f7f8f9;
}

#project-intern-supporters .table-striped > tbody > tr:nth-of-type(even) {
  background: #f2f3f3;
}

.project-intern-terms-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
  vertical-align: middle;
}

.project-intern-submit-button {
  background-color: #2e91d5;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 170px;
  height: 40px;
  float: right;
}
.project-intern-submit-button:hover,
.project-intern-submit-button:active {
  background-color: #51a9e6;
}

.project-intern-fee-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #00522c;
  margin-top: 0px;
  margin-bottom: 30px;
}

.project-inter-support-value {
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 24px;
  color: #00522c;
  margin-top: 7px;
}

.project-intern-support-type-item {
  display: inline-block;
  margin-right: 160px;
}

.project-intern-support-type-item .form-group {
  margin-bottom: 10px;
}

.ql-video {
  display: block;
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 1600px) {
  .project-intern-toolbar-button {
    width: 180px;
  }

  .ql-video {
    height: 440px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-intern-carousel-image {
    height: 600px;
  }

  .ql-video {
    height: 366px;
  }
}

@media only screen and (max-width: 992px) {
  .project-intern-carousel-image {
    height: 558px;
  }

  .project-intern-carousel-toolbar-container {
    top: -33px;
    left: 20px;
  }

  .project-intern-badge {
    margin: 0 6px;
    width: 65px !important;
  }

  .ql-video {
    height: 350px;
  }
}

@media only screen and (max-width: 768px) {
  .project-intern-button-container {
    margin-top: 8px;
  }

  .project-intern-carousel-image {
    height: 300px;
  }

  .project-intern-button {
    width: 160px;
    height: 40px;
    font-size: 14px !important;
  }

  .project-intern-values {
    padding: 15px;
  }

  .project-intern-values-title {
    font-size: 14px;
    margin-top: 15px;
  }

  .project-intern-values-value {
    font-size: 20px !important;
    margin-top: -5px;
    margin-bottom: 15px;
  }

  .project-intern-support-type-item {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .ql-video {
    height: 410px;
  }
}

@media only screen and (max-width: 650px) {
  #project-intern-titles h2 {
    font-size: 24px;
  }

  #project-intern-titles h4 {
    font-size: 16px;
  }

  .project-intern-summary-title {
    font-size: 28px;
  }

  .project-intern-toolbar-container {
    float: none;
    margin-left: -15px;
  }

  .project-intern-toolbar-button {
    width: 100%;
  }

  .project-intern-toolbar-item {
    width: 100%;
    margin-left: 0;
  }

  .project-intern-submit-button {
    margin-top: 30px;
    width: 100%;
    float: none;
  }

  .project-intern-toolbar-text {
    margin-left: 15px;
    margin-right: 15px;
  }

  .ql-video {
    height: 350px;
  }
}

@media only screen and (max-width: 480px) {
  .project-intern-button-container {
    float: none;
  }

  .project-intern-button {
    width: 115%;
    margin-left: -20px;
    margin-top: 30px;
  }

  .ql-video {
    height: 270px;
  }
}

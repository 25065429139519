.header {
  position: relative;
  background-color: #f2f3f3;
  height: 70px;
  z-index: 50;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.header-logo {
  float: left;
  top: 9px;
  height: 50px;
  margin-left: -10px;
}

.header-menu-button {
  float: right;
  margin: 14px 20px;
}

.header-menu-button > img {
  width: 40px;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
}

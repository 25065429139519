.proponent-documents-list-item-body-container1 {
  width: 100%;
}

.proponent-documents-list-item-expanded-container {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  padding: 10px 30px 30px 112px;
}

.proponent-documents-list-item-expanded-download-icon {
  cursor: pointer;
  vertical-align: middle;
  height: 25px;
}

.proponent-documents-list-item-expanded-download-label {
  display: inline-block;
  margin-left: 15px;
  width: 60px;
  vertical-align: middle;
}

.proponent-documents-list-item-expanded-separator {
  height: 1px;
  width: 80%;
  border: 1px solid #888888;
  margin: 40px 0;
}

@media only screen and (max-width: 992px) {
  .proponent-documents-list-item-body {
    font-size: 16px;
  }

  .proponent-documents-list-item-expanded-container {
    font-size: 14px;
    padding: 10px 25px 30px 110px;
  }
}

@media only screen and (max-width: 768px) {
  .proponent-documents-list-item-body {
  }

  .proponent-documents-list-item-expanded-container {
    padding: 10px 25px 30px 75px;
  }

  .proponent-documents-list-item-expanded-download-icon {
    height: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .proponent-documents-list-item-body {
    font-size: 14px;
  }

  .proponent-documents-list-item-expanded-download-label {
    margin-left: 0;
    margin-top: 10px;
    width: auto;
  }
}

.card-field-container {
  background: #f7f8f9;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 60px;
  padding: 0 20px;
  margin: 1px;
}

.card-field-label {
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
  line-height: 60px;
}

.card-field-value {
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  width: calc(100% - 155px);
  margin: 8px;
}

.blog-list-article-card {
  position: relative;
  width: 100%;
  height: 320px;
  margin-bottom: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
}

.blog-list-article-title-container {
  position: absolute;
  left: 0;
  bottom: 8%;
  width: 100%;
  height: 160px;
  background-color: rgb(0, 0, 0, 0.6);
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  padding: 15px 40px 45px 40px;
}

.blog-list-title {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  height: 57px;
  overflow: hidden;
}

.blog-list-description {
  font-weight: normal;
  font-size: 14px;
  height: 48px;
  overflow: hidden;
}

.blog-list-date {
  font-weight: normal;
  font-size: 12px;
  position: absolute;
  bottom: 15px;
  left: 40px;
}

.blog-list-button-container {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.blog-list-button {
  height: 40px;
  width: 190px;
}

.blog-list-tags-container {
  margin-top: 30px;
}

.blog-list-tags-title {
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 22px;
  color: #00522c;
  position: relative;
  margin-bottom: 30px;
}

.blog-list-tags-title::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #b2b2b2;
  position: absolute;
  top: 45px;
  left: 0;
}

.blog-list-tag-card {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  color: #00522c;
  cursor: pointer;
  display: inline-block;
  background-color: #eceded;
  height: 32px;
  padding: 6px 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.blog-list-tag-card-selected {
  color: white;
  background-color: #00522c;
}

.blog-post-title {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 32px;
  color: #00522c;
  margin-bottom: 15px;
}

.blog-post-summary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
}

.blog-post-infobar-container {
  margin-top: 20px;
}

.blog-post-infobar-item {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0;
}

.blog-post-infobar-item > div {
  display: inline-block;
  vertical-align: middle;
}

.blog-post-infobar-item > img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  height: 20px;
}

.blog-post-infobar-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  vertical-align: middle;
}

.blog-post-infobar-separator {
  background-color: #00522c;
  width: 1px;
  height: 20px;
  margin: 0 15px;
  display: inline-block;
  vertical-align: middle;
}

.blog-post-banner {
  margin-top: 30px;
  height: auto;
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

.blog-post-body {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00522c;
  margin-top: 30px;
}

.blog-post-tag-card {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  color: #00522c;
  display: inline-block;
  background-color: #eceded;
  height: 32px;
  padding: 6px 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.blog-post-social-bar-container {
  margin-top: 25px;
  position: relative;
}

.blog-post-social-bar-container::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #b2b2b2;
  position: absolute;
  top: 90px;
  left: 0;
}

.blog-post-social-bar {
  text-align: center;
}

.blog-post-social-bar-item {
  margin: 0 15px;
  height: 50px;
}

.blog-post-more-articles-container {
  margin-top: 60px;
}

.blog-post-more-articles-title {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 24px;
  color: #00522c;
}

.blog-post-more-articles-button {
  width: 190px;
  height: 40px;
  float: right;
  margin-top: 30px;
}

.blog-post-article-card {
  position: relative;
  height: 240px;
  margin: 15px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
}

.blog-post-article-card-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  height: 50px;
  overflow: hidden;
}

.blog-post-article-card-description {
  font-weight: normal;
  font-size: 14px;
  height: 49px;
  overflow: hidden;
}

.blog-post-article-card-date {
  font-weight: normal;
  font-size: 12px;
  position: absolute;
  bottom: 18px;
  left: 42px;
}

@media only screen and (max-width: 1200px) {
  .blog-list-title {
    font-size: 20px;
    height: 49px;
  }

  .blog-list-description {
    font-size: 13px;
    height: 58px;
  }
}

@media only screen and (max-width: 992px) {
  .blog-post-title {
    font-size: 28px;
  }

  .blog-post-infobar-item {
    width: 100%;
  }

  .blog-post-social-bar-item {
    margin: 0 8px;
    height: 45px;
  }
}

@media only screen and (max-width: 650px) {
  .blog-post-summary {
    font-size: 14px;
  }

  .blog-post-infobar-container {
    margin-top: 15px;
  }

  .blog-post-infobar-text {
    font-size: 13px;
  }

  .blog-post-infobar-item > img {
    margin-right: 10px;
    height: 20px;
  }

  .blog-post-banner {
    margin-top: 30px;
  }

  .blog-post-social-bar-item {
    margin: 0 5px;
    height: 40px;
  }
}

.areaofinterest-form-input-text {
  float: left;
  width: calc(100% - 295px);
  margin: 0 15px;
}

.areaofinterest-form-input-image {
  float: right;
  margin: 0 15px;
  width: 235px;
}

@media only screen and (max-width: 768px) {
  .areaofinterest-form-input-text {
    float: left;
    width: calc(100% - 30px);
    margin: 0 15px;
  }

  .areaofinterest-form-input-image {
    float: left;
  }
}

.supporter-home-banner-container {
  position: relative;
  overflow: hidden;
  height: 420px;
}

.supporter-home-banner {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.supporter-home-stats {
  position: absolute;
  left: 50%;
  bottom: 50%;
  margin: 0;
  transform: translate(-50%, 50%);
}

@media only screen and (max-width: 768px) {
  .supporter-home-banner-container {
    height: 340px;
  }
}

@media only screen and (max-width: 650px) {
  .supporter-home-banner-container {
    height: 280px;
  }
}

.tabprogress-container {
  margin-bottom: 20px;
}

.tabprogress-item-icon {
  margin-right: 20px;
}

.tabprogress-item-base {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  display: inline-block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}

.tabprogress-item-default {
  color: #00522c;
  background: #f2f3f3;
}

.tabprogress-item-selected {
  position: relative;
  color: #ffffff;
  background: #00522c;
}

.tabprogress-item-selected::after {
  content: " ";
  position: absolute;
  top: 44px;
  left: 50%;
  width: 30px;
  height: 30px;
  background: #00522c;
  border-radius: 0px 0px 6px 0px;
  transform: translate(-50%, 0) matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}

.tabprogress-item-ok {
  background: #24a862;
  color: #ffffff;
}

.tabprogress-item-warning {
  background: #efa500;
  color: #ffffff;
}

.tabprogress-item-error {
  background: #b73520;
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .tabprogress-container {
    border-radius: 6px;
    overflow: hidden;
  }

  .tabprogress-item-base {
    font-size: 16px;
    display: block;
    height: 40px;
    line-height: 40px;
  }

  .tabprogress-item-selected::after {
    content: " ";
    display: none;
  }
}

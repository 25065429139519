.supports-intern-data-container {
  background: #f2f3f3;
  width: calc(100% + 30px);
  height: 60px;
  margin-bottom: 15px;
  display: inline-table;
}

.supports-intern-attache-button-container {
  width: 39px;
  height: 34px;
  cursor: pointer;
}

.supports-intern-attache-button-container > div {
  margin-left: 12px !important;
}

.supports-intern-attache-button-container > div > input {
  cursor: pointer;
}

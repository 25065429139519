.date-range-container {
  padding-top: 22px;
  margin-bottom: 20px;
}

.date-range-item {
  display: inline-block;
}

.date-range-input {
  width: calc(50% - 39px);
}

.date-range-label {
  margin: 0 10px;
}

@media only screen and (max-width: 768px) {
  .date-range-container {
    padding-top: 0;
  }
}

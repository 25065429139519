.range-container {
  height: 60px;
}

.range-label-container {
  height: 32px;
}

.range-label {
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 11px;
  color: rgba(53, 53, 53, 0.7);
}

.rc-slider-handle,
.rc-slider-handle:focus,
.rc-slider-handle:active {
  border: none !important;
}

.searchContainer {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.searchBar {
  /* background-image: url("https://vbio.blob.core.windows.net/public/assets/img/search-icon.png"); */
  background-position: 99% 50%;
  background-repeat: no-repeat;
  height: 40px;
  width: 100%;
}

.searchBarButton {
  height: 40px;
  width: 100%;
  margin-top: 7px;
}

.searchBarButton:hover {
  color: rgba(0, 0, 0, 0.35);
}

@media only screen and (max-width: 768px) {
  .searchBarButton {
    margin-top: 40px;
  }
}

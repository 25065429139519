.infoicon-container {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.infoicon-container > img {
  cursor: pointer;
  width: 20px;
}

.infoicon-tooltip {
  position: fixed;
  background: #f7f8f9;
  border: 1px solid #00522c;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  z-index: 100;
  padding: 5px 10px;
  text-align: start;
}

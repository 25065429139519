.footer {
  background-color: #f2f3f3;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
}

.footer-logo {
  height: 45px;
}

.footer-open-container {
  margin-top: 48px;
}

.footer-restrict-container {
  text-align: center;
  margin: 30px 0;
}

.footer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #888888;
}

.footer-text > a {
  color: #888888;
}

.footer-social-container {
  width: 100%;
  vertical-align: middle;
}

.footer-social-icon {
  display: inline-block;
  margin-right: 20px;
  width: 40px;
}

.footer-title {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #888888;
  text-transform: uppercase;
}

.footer-title::after {
  content: " ";
  position: absolute;
  background: #888888;
  left: 0;
  top: 28px;
  width: 120px;
  height: 1px;
}

.footer-carousel {
  height: 60px;
  margin: 32px 0;
}

.footer-carousel-item {
  width: 65%;
  margin: 0 10px;
  cursor: pointer;
}

.footer-menu-container {
  margin: 25px 0;
}

.footer-menu-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #888888;

  margin-bottom: 3px;
}

.footer-menu-item > a {
  color: #888888;
}

@media only screen and (max-width: 1600px) {
  .footer-content {
    padding-bottom: 0;
  }

  .footer-title::after {
    width: 80px;
  }

  .footer-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-social-icon {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .footer-open-container {
    margin: 36px 0;
  }

  .footer-social-container {
    text-align: center;
  }

  .footer-bottom {
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .footer-text {
    font-size: 12px;
  }

  .footer-social-icon {
    margin-right: 12px;
    width: 35px;
  }
}

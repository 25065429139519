.projects-list-report-button {
  margin-top: 61px;
  margin-bottom: 50px;
  height: 60px;
  padding: 19px 0;
}

.sortByComponent {
  position: absolute;
  right: 0px;
  padding-right: 60px;
}

.sortBySelect {
  width: 369px;
  height: 50px;
}

.searchOptionsContainer {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.searchOptionsSelect {
  height: 40px;
  width: 100%;
}

.fixRowMargin {
  margin-left: 0px;
}

.dateRangeDiv {
  padding-left: 0px;
  margin-top: 33px;
}

.dateRangeInput {
  margin: 0px;
  margin-top: 6px;
  padding: 0px;
  height: 30px;
  width: 100%;
}

.noPaddingRight {
  padding-right: 0px;
}

.noPaddingLeft {
  padding-left: 0px;
}

.master-list-button-container {
  float: right;
}

.master-list-back-container {
  float: left;
  margin-top: 60px;
  margin-left: 0;
  width: 70px;
}

@media only screen and (max-width: 768px) {
  .master-list-button-container {
    float: none;
  }

  .master-list-back-container {
    float: none;
    margin-top: 0;
    margin-bottom: 60px;
  }
}

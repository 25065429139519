.login-container {
  max-width: 600px;
  margin-top: 100px;
  padding: 0;
}

.login-container .form-group {
  margin-bottom: 20px;
}

.login-container .button {
  font-size: 14px !important;
}

.login-container .form-body {
  margin-top: 0;
}

.login-form-container {
  padding: 0 15px;
}

.login-submit {
  margin-top: 40px;
}

.login-forget-password-text {
  font-style: normal;
  font-weight: bold;
  line-height: 32px;
  font-size: 20px;
  color: #24a862;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  float: left;
}

.login-submit-button {
  width: 100%;
  height: 40px;
}

.register-button {
  height: 40px;
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .login-container {
    margin-top: 30px;
  }

  .login-forget-password-text {
    margin-bottom: 15px;
  }
}

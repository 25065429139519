.list-header-container {
  margin-top: 30px;
  margin-bottom: 10px;
}

.list-add-button {
  float: right;
  height: 30px;
}

.list-item-container {
  margin-bottom: 15px;
}

.list-item-icon {
  height: 100%;
}

.list-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #00522c;
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  width: calc(100% - 215px);
}

.list-item-buttons-container {
  float: right;
  height: 100%;
}

.list-item-button {
  display: inline-block;
  vertical-align: middle;
  margin-top: 19px;
  cursor: pointer;
  height: 20px;
}

.list-zebra-color-even {
  background: #f2f3f3;
}

.list-zebra-color-odd {
  background: #f7f8f9;
}

.list-zebra-item-container {
  padding: 15px;
  display: table;
  width: 100%;
}

.list-zebra-item-header-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00522c;
  padding-left: 5px;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.list-zebra-item-body-container1 {
  display: inline-table;
  height: 100%;
  width: calc(100% - 175px);
  vertical-align: top;
}

.list-zebra-item-body-container2 {
  display: table-cell;
  vertical-align: middle;
}

.list-zebra-item-buttons-container1 {
  display: table;
  float: right;
  height: 100%;
  vertical-align: middle;
  margin-right: 20px;
}

.list-zebra-item-buttons-container2 {
  display: table-cell;
  vertical-align: middle;
}

.list-zebra-item-button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  height: 20px;
}

.list-zebra-item-text {
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  font-size: 18px;
  color: #24a862;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.list-zebra-item-text-bold {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #24a862;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

@media only screen and (max-width: 1600px) {
  .list-zebra-item-button-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 992px) {
  .list-zebra-item-body-container1 {
    margin-left: 0px;
  }

  .list-zebra-item-button-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .list-zebra-item-buttons-container1 {
    margin-right: 10px;
  }

  .list-item-title {
    font-size: 14px;
    margin-left: 20px;
  }

  .list-zebra-item-header-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .list-item-title {
    margin-left: 15px;
  }
}

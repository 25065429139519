.status-list-item-image-container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 75px;
  border-radius: 3px;
  overflow: hidden;
}

.status-list-item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.status-list-image-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
}

.list-body-actions-documents {
  float: right;
}

.proponent-documents-list-item-expanded-separator {
  height: 1px;
  width: 80%;
  border: 1px solid #888888;
  margin: 40px 0;
}

.proponent-documents-list-justitication-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #24a862;

  cursor: pointer;
}

.proponent-documents-list-modal-container {
  width: 460px;
}

.proponent-documents-list-modal-title {
  font-size: 22px;
}

.proponent-documents-list-modal-textarea-container {
  margin: 15px 0;
}

.proponent-documents-list-modal-textarea {
  width: 100%;
}

.proponent-documents-list-modal-button {
  height: 40px;
  width: 160px;
}

@media only screen and (max-width: 992px) {
  .proponent-user-name {
    font-size: 18px;
    margin-left: -15px;
  }

  .proponent-user-email {
    font-size: 16px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 768px) {
  .status-list-item-image-container {
    width: 40px;
  }

  .status-list-image-icon {
    width: 30px;
  }

  .proponent-user-name {
    font-size: 16px;
  }

  .proponent-user-email {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .proponent-user-name {
    margin-left: -25px;
  }

  .proponent-user-email {
    margin-left: -25px;
  }
}
